$color_1: rgb(53, 155, 155);
$color_2: white;
$background-color_1: black;
$background-color_2: transparent;

.burger-menu {
	display: none;
}
.hamburger-menu-icon {
	background: url('../../assets/images/menu_hamburger_mobile.webp') no-repeat center center;
	background-size: cover;
	cursor: pointer;
	display: none;
}
/* Ici : media queries pour la première partie tablette */
@media screen and (max-width: 1580px) {
	.navlink-display-none {
		display: none;
	}
	.navlink-display-flex {
		display: flex;
		&:hover {
			color: $color_1;
		}
		&:focus {
			color: $color_1;
		}
		&:active {
			color: $color_1;
		}
	}
}
/* Ici : media queries pour la seconde partie tablette */
@media screen and (max-width: 1023px) {
	.burger-menu {
		position: absolute;
		z-index: 3;
		top: 130px;
		gap: 20px;
		padding-top: 15px;
		padding-bottom: 15px;
		padding-left: 15px;
		padding-right: 30px;
		display: flex;
		border: 1px solid white;
		background-color: $background-color_1;
		overflow: visible;
		flex-direction: column;
		width: fit-content;
		height: fit-content;
		a {
			font-size: 18px;
			color: $color_2;
			text-decoration: none;
			margin-left: 5px;
			text-transform: lowercase;
		}
	}
	.hamburger-menu-icon {
		position: absolute;
		margin-left: 10px;
		top: 90px;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		width: 40px;
		height: 40px;
		background-color: $background-color_2;
	}
}
/* Ici : media queries pour la partie mobile */
@media only screen and (max-width: 767px) {
	.burger-menu {
		margin-left: 10px;
		a {
			font-size: 14px;
		}
	}
}