$color_1: midnightblue;
$color_2: white;

.error-page {
	background: url(../../assets/images/error404.webp) no-repeat center center fixed;
	background-size: cover;
	position: relative;
	width: calc(80% - 2px);
	border: 1px solid $color_2;
}
.error-overlay {
	position: absolute;
	background: rgb(219, 197, 190, .50);
	width: 100%;
	height: 100%;
}
.error404-div {
	position: absolute;
	width: 80%;
	min-height: 100vh;
	padding-right: 10%;
	padding-left: 10%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 80px;
	overflow: hidden;
	h1 {
		text-align: center;
		font-size: 36px;
		cursor: default;
	}
	a {
		text-align: center;
		font-size: 32px;
		color: $color_1;
		text-decoration: none;
	}
}
/* Ici : media queries pour la seconde partie tablette */
@media screen and (max-width: 991px) {
	.error-page {
		width: calc(100% - 2px);
	}
	.error404-div {
		h1 {
			font-size: 22px;
		}
		a {
			font-size: 18px;
		}
	}
}