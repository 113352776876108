$color_1: white;
$background-color_1: black;

.new-comment-form {
    position: relative;
    outline: 1px solid $color_1;
    width: 80%;
    height: fit-content;
    max-width: 748px;
    min-height: 70px;
    padding: 0;
    margin: 0;
    background-color: $background-color_1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .comment-citation-div {
        display: flex;
        flex-direction: column;
        position: relative;
        color: $color_1;
        width: 100%;
        height: fit-content;
        font-size: 16px;
        font-family: Arial, Helvetica, sans-serif;
        text-align: justify;
        .comment-citation-cancel {
            position: absolute;
            width: 100%;
            right: 15px;
            margin-top: 5px;
            width: 10px;
            height: 10px;
            font-size: 10px;
            cursor: pointer;
        }
        .comment-citation-content {
            overflow-wrap: break-word;
            margin-bottom: 0;
            margin-top: 4px;
            margin-left: 10px;
            padding: 0;
            min-height: 40px;
            color: $color_1;
            font-size: 9px;
            background-color: $background-color_1;
            width: 90%;
            height: auto;
        }
    }
    .new-comment-form-textarea {
        width: 95%;
        min-height: 40px;
        height: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 8px;
        border: none;
        outline: 1px solid rgba(255, 255, 255, .6);
        color: $color_1;
        font-size: 9px;
        line-height: 13px;
        background-color: $background-color_1;
    }
    .new-comment-form-textarea::placeholder {
        color: $color_1;
        opacity: .6;
    }
}
.comment-form-suggestions-list {
    font-family: Arial, Helvetica, sans-serif;
    position: absolute;
    border: 1px solid #ccc;
    background-color: $background-color_1;
    z-index: 1000;
    list-style: none;
    margin: 0;
    padding: 0;
    min-width: 150px;
    max-height: 109px;
    overflow-y: auto;
    color: $color_1;
    font-size: 9px;
    .comment-form-suggestion-item {
        cursor: pointer;
        padding: 5px 10px;
        border-bottom: 1px solid #eee;
    }
}
/* Ici : media queries pour la seconde partie tablette */
@media screen and (max-width: 991px) {
	.new-comment-form-textarea {
		width: calc(95% - 4px);
		min-height: 50px !important;
		margin-top: 1px !important;
		margin-bottom: 1px !important;
		padding: 5px !important;
		font-size: 7px;
		line-height: 9px;
	}
    .comment-form-suggestions-list {
        font-size: 7px;
        max-height: 79px;
        min-width: 100px;
        .comment-form-suggestion-item {
            cursor: pointer;
            padding: 3px 6px;
            border-bottom: 1px solid #eee;
        }
    }
}
/* Ici : media queries pour la première partie smartphone */
@media screen and (max-width: 660px) {
	.comment-citation-content {
		width: 86% !important;
	}
	.new-comment-form {
		height: fit-content;
		gap: 3px;
		padding-top: 3px !important;
		padding-bottom: 3px !important;
	}
	.new-comment-form-section {
		width: calc(95% - 1px) !important;
	}
	.new-comment-form-textarea {
		width: calc(93% - 4px) !important;
	}
}