$font-family_1: Arial, Helvetica, sans-serif;
$color_1: rgb(53, 155, 155);
$color_2: white;
$background-color_1: white;

.legal-page {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 50px;
    padding-bottom: 50px;
	font-family: $font-family_1;
	min-height: 100vh;
	width: calc(70% - 2px);
	border-left: 1px solid $color_2;
    border-right: 1px solid $color_2;
	margin-left: auto;
	margin-right: auto;
	background-color: $background-color_1;
	display: flex;
	flex-direction: column;
	align-items: center;
    text-align: justify;
	justify-content: center;
    cursor: default;
    h1 {
        margin-top: 0;
    }
}
/* Ici : media queries pour la seconde partie tablette */
@media screen and (max-width: 991px) {
	.legal-page {
		width: calc(90% - 2px);
	}
}
/* Ici : media queries pour la troisième partie tablette */
@media screen and (max-width: 767px) {
    .legal-page {
        font-size: 14px;
        h1 {
            font-size: 22px;
        }
        h2 {
            font-size: 16px;
        }
        h3 {
            font-size: 14px;
        }
    }
}