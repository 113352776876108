.del-follow-topic-main {
    margin-left: auto;
	margin-right: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 94%;
	height: 40px;
	margin-top: 12px;
	padding: 8px;
	border: 1px solid black;
	border-radius: 10px;
	background-color: lightgray;
    font-family: Arial, Helvetica, sans-serif;
    overflow: hidden;
    p {
        white-space: wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 90%;
        height: fit-content;
        cursor: default;
    }
    .topic-action {
        line-height: 20px;
        margin-left: 20px;
        cursor: pointer;
    }
    .bulle {
        width: 30px; 
        height: 30px;
        min-width: 30px;
        min-height: 30px;
    }
}
@media screen and (max-width: 767px) {
	.del-follow-topic-main p {
		font-size: 12px;
	}
}