$color_1: rgb(53, 155, 155);
$color_2: lightcyan;
$background-color_1: black;
$font-family_1: Arial, Helvetica, sans-serif;


.home-page {
	border: 1px solid $color_2;
	width: calc(80% - 2px);
}
.div-welcome {
	background: url(../../assets/images/background05.webp) no-repeat center center;
	background-size: cover;
	height: 600px;
	padding-top: 100px;
	cursor: default;
	overflow: hidden;
}
.welcome-message {
	text-transform: lowercase;
	color: $color_1;
	text-align: justify;
	width: 45%;
	font-size: 20px;
	line-height: 45px;
	margin-left: 50px;
}
.transition1 {
	height: 50px;
	width: 100%;
	background-color: $background-color_1;
}
.div-categories {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: url(../../assets/images/background02.webp) no-repeat center center;
	background-size: cover;
	font-size: 34px;
	margin: 0;
	cursor: default;
	h2 {
		font-size: 32px;
		color: white;
	}
}
.transition2 {
	height: 40px;
	width: 100%;
	background: linear-gradient(black, transparent);
}
.card-list {
	min-height: 1000px;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}
/* Ici : media queries pour la seconde partie desktop */
@media screen and (max-width: 1380px) {
	.welcome-message {
		margin-top: 40px;
		font-size: 18px;
		line-height: 40px;
	}
}
/* Ici : media queries pour la seconde partie tablette */
@media screen and (max-width: 991px) {
	.home-page {
		width: calc(100% - 2px);
	}
	.welcome-message {
		line-height: 40px;
		font-family: $font-family_1;
		font-weight: bold;
		text-transform: none;
	}
}
/* Ici : media queries pour la troisième partie tablette */
@media screen and (max-width: 767px) {
	.div-welcome {
		height: 400px;
		padding-top: 50px;
	}
	.welcome-message {
		width: 75%;
		margin-left: auto;
		margin-right: auto;
	}
	.div-categories {
		h2 { 
			font-size: 27px;
		}
	}
}
/* Ici : media queries pour la première partie smartphone */
@media screen and (max-width: 660px) {
	.div-categories {
		h2 {
			font-size: 22px;
		}
	}
	.div-welcome {
		height: fit-content;
	}
	.welcome-message {
		font-size: 14px;
		line-height: 30px;
	}
}
/* Ici : media queries pour la seconde partie smartphone */
@media screen and (max-width: 420px) {
	.welcome-message {
		line-height: 26px;
		width: 70%;
	}
}