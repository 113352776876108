$color_1: white;
$color_2: rgb(53, 155, 155);
$color_3: black;
$font-family_1: Arial, Helvetica, sans-serif;
$font-family_2: 'Starjedi';
$background-color_1: black;
$background-color_2: white;

.forum {
	background: url(../../assets/images/forum.webp) no-repeat center center fixed;
	background-size: cover;
	position: relative;
	color: $color_1;
	width: calc(80% - 2px);
	border: 1px solid $color_1;
	min-height: 1300px;
}
.forum-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: $background-color_1;
	opacity: 0.6;
}
.forum-content {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
	text-align: justify;
	font-family: $font-family_1;
	.forum-div-theme-spinner {
		position: relative;
		.theme-spinner {
			position: absolute;
			left: calc(50% - 150px);
			top: 150px;
			z-index: 3;
			width: 300px;
			height: 300px;
		}
	}
}
.chart-section {
	height: fit-content;
	overflow: hidden;
	margin-top: 60px;
	width: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: default;
}
.forum-title {
	font-family: $font-family_2;
	margin-top: 50px;
	cursor: default;
}
.forum-div-categories {
	margin-top: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 2px solid $color_2;
	border-radius: 10px;
	width: 50%;
	background-color: $background-color_1;
	h2 { 
		width: 100%;
		text-align: center;
		color: $color_1;
		font-family: $font-family_2;
		padding-bottom: 10px;
		cursor: default;
	}
}
.div-category {
	color: $color_2;
	width: 100%;
	height: 65px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: all, .4s;
	&:hover { 
		text-align: center;
		background-color: $background-color_2;
		color: $color_3;
		font-size: 20px;
	}
}
/* Ici : media queries pour la seconde partie desktop */
@media screen and (max-width: 1380px) {
    .forum-div-categories {
        width: 65%;
    }
}
/* Ici : media queries pour la première partie tablette */
@media screen and (max-width: 1023px) {
    .forum-div-categories {
        width: 80%;
    }
}
/* Ici : media queries pour la seconde partie tablette */
@media screen and (max-width: 991px) {
	.forum {
		width: calc(100% - 2px);
	}
	.forum-title {
		font-family: $font-family_2;
		width: 80%;
		text-align: center;
		font-size: 22px;
		cursor: default;
	}
	.chart-text {
		font-size: 14px;
		width: 90%;
	}
	.forum-div-categories {
		h2 {
			font-size: 18px;
		}
	}
	.div-category {
		width: 100%;
		height: 57px;
		font-size: 16px;
		&:hover {
			font-size: 18px;
		}
	}
}
/* Ici : media queries pour la troisième partie tablette */
@media screen and (max-width: 767px) {
	.theme-spinner {
		left: calc(50% - 100px) !important;
		width: 200px !important;
		height: 200px !important;
	}
}
/* Ici : media queries pour la première partie smartphone */
@media screen and (max-width: 660px) {
	.forum-div-categories {
		width: 90%;
	}
	.div-category {
		width: 100%;
		height: 50px;
		font-size: 14px;
		&:hover { 
			font-size: 16px;
		}
	}
}