$font-family_1: Arial, Helvetica, sans-serif;
$background-color_1: lightgray;

.AdminModifyUserForm {
	margin-top: 30px;
	margin-bottom: 45px;
}
.user-data-table {
	margin-left: auto;
	margin-right: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 94%;
	height: 40px;
	margin-top: 12px;
	padding: 8px;
	border: 1px solid black;
	border-radius: 10px;
	background-color: $background-color_1;
	font-family: $font-family_1;
}
.user-data-picture {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	min-height: 40px;
	width: 40px;
	min-width: 40px;
	border: 1px solid gray;
	border-radius: 10px;
	overflow: hidden;
	cursor: pointer;
	img { 
		max-height: 40px;
	}
	&:hover { 
		outline: 4px solid red;
	}
}
.user-data-name-div {
	padding-left: 10px;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}
.user-data-name {
	cursor: default;
	text-transform: lowercase;
}
.user-data-delete-image {
	width: 30px;
	height: 30px;
	cursor: pointer;
}
.user-data-delete-button {
	width: 37px;
	height: 37px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	&:hover { 
		outline: 4px solid red;
	}
}
/* Ici : media queries pour la troisième partie tablette */
@media screen and (max-width: 767px) {
	.user-data-name {
		font-size: 12px;
	}
}