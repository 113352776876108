$color_1: white;
$color_2: black;
$color_3: rgb(53, 155, 155);
$font-family_1: Arial, Helvetica, sans-serif;
$background-color_1: black;
$background-color_2: white;

.collapse-main {
	width: 50%;
	height: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 35px;
	margin-bottom: 35px;
}
.collapse-bar {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	width: calc(100% - 2px);
	background-color: $background-color_1;
	outline: 1px solid $color_3;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}
.collapse-title {
	color: $color_1;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	font-family: $font-family_1;
	margin-bottom: 0;
	margin-top: 0;
	cursor: default;
}
.collapse-div-text {
	background-color: $background-color_2;
	height: 300px;
	width: 100%;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}
object {
	width: 99%;
	height: 100%;
	color: $color_2;
	text-align: justify;
}
/* Ici : media queries pour la seconde partie desktop */
@media screen and (max-width: 1380px) {
    .collapse-main {
        width: 65%;
    }
}
/* Ici : media queries pour la première partie tablette */
@media screen and (max-width: 1023px) {
    .collapse-main {
        width: 80%;
    }
    .collapse-title {
        font-size: 18px;
    }
}
/* Ici : media queries pour la première partie smartphone */
@media screen and (max-width: 660px) {
	.collapse-main {
        width: 90%;
    }
}