$color_1: white;
$background-color_1: transparent;
$background-color_2: black;

.topics {
	background: url(../../assets/images/star-wars-episode9-team.webp) no-repeat center center fixed;
	background-size: cover;
	color: $color_1;
	position: relative;
	width: calc(80% - 2px);
	border: 1px solid $color_1;
}
.topics-div-return {
	position: absolute;
	display: flex;
	width: 100%;
	justify-content: flex-start;
}
.arrow-link {
	z-index: 2;
	margin-left: 15px;
	margin-top: 20px;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	background-color: $background-color_1;
	transition-property: all;
	transition-duration: .2s;
	&:hover { 
		background-color: black;
		filter: invert(1);
	}
	img {
		width: 80px;
		height: 80px;
	}
}
.topics-main {
	z-index: 1;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.topics-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: $background-color_2;
	opacity: 0.6;
}
.topics-page-title {
	margin-top: 50px;
	cursor: default;
}
.topics-list {
	margin-top: 50px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	.topics-list-empty-message {
		font-family: Arial, Helvetica, sans-serif;
		font-size: 28px;
	}
}
.topic-creation-form {
	margin-top: 50px;
	margin-bottom: 100px;
}
/* Ici : media queries pour la seconde partie desktop */
@media screen and (max-width: 1380px) {
    .topics-list-empty-message {
		font-size: 20px !important;
	}
}
/* Ici : media queries pour la première partie tablette */
@media screen and (max-width: 1023px) {
    .topics-list-empty-message {
		font-size: 18px !important;
	}
	.topic-creation-form {
		margin-top: 70px;
		margin-bottom: 30px;
	}
}
/* Ici : media queries pour la seconde partie tablette */
@media screen and (max-width: 991px) {
	.topics {
		width: calc(100% - 2px);
	}
	.topics-page-title {
		margin-top: 60px;
		width: 80%;
		text-align: center;
		font-size: 22px;
		cursor: default;
	}
}