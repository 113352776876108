.unvalid-password-text {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: center;
}
.other-user-form-update {
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
}
.other-user-input {
    border: none;
    border-radius: 20px;
    width: calc(100% - 30px);
    height: 31px;
    margin: 0;
    font-size: 18px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: white;
}
.other-user-input:focus {
    outline: 1px solid gainsboro;
}
.div-input-file-wrapper {
    width: 100%;
	display: flex;
	justify-content: space-around;
	.input-file-undo-upload {
		width: 33px;
		height: 33px;
		margin-left: 6px;
		background: url('../../assets/images/undo.svg') no-repeat center center;
		background-size: cover;
		cursor: pointer;
		transition: transform 0.5s ease;
	}
	.input-file-undo-upload:hover {
		transform: rotate(-360deg);
	}
}
input[type='file'] {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    cursor: pointer;
}
#div-input-file {
    color: #747474;
    position: relative;
    width: calc(94% + 30px);
    height: 33px;
    border-radius: 20px;
    font-size: 18px;
    background-color: white;
    font-family: Arial, Helvetica, sans-serif;
}
.div-imput-file-content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 20px;
}
.input-other-user-valid-img {
    background-color: transparent;
    width: 25px;
    height: 25px;
    margin-left: 20px;
}

.other-user-submit-button {
    font-family: Arial, Helvetica, sans-serif;
    border: none;
    outline: none;
    border-radius: 20px;
    width: 50%;
    height: 33px;
    margin: 0;
    font-size: 18px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: white;
    margin-bottom: 30px;
    cursor: pointer;
}
.other-user-submit-button:active, .other-user-submit-button:hover {
    outline: 4px solid gainsboro;
    border-radius: 20px;
}
.display-none {
	display: none;
}
.display-flex {
	display: flex;
}
/* Ici : media queries pour la troisième partie tablette */
@media screen and (max-width: 767px) {
    .unvalid-password-text {
        font-size: 10px;
    }
    .other-user-form-update {
        gap: 25px;
    }
    .other-user-input {
        height: 25px;
        font-size: 14px;
    }
    #div-input-file {
        height: 25px;
        font-size: 14px;
    }
    .input-other-user-valid-img {
        width: 20px;
        height: 20px;
        margin-left: 10px;
    }
    .other-user-submit-button {
        height: 25px;
        font-size: 14px;
    }
}