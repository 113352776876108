$color_1: white;
$color_2: lightcyan;
$color_3: gainsboro;
$font-family_1: 'Starjout';
$font-family_2: Arial, Helvetica, sans-serif;
$background-color_1: black;

.header {
	width: 80%;
	height: 140px;
	margin-left: auto;
	margin-right: auto;
	background-color: $background-color_1;
	display: flex;
	justify-content: center;
	align-items: center;
}
.navbar {
	width: calc(100% - 2px);
	height: 100%;
	display: flex;
	background-image: url(../../assets/images/banner02.webp);
	background-size: 110%, 110%;
	background-position: 50%, 50%;
	border: 1px solid lightcyan;
	position: relative;
}
.link-logo {
	width: 110px;
	height: auto;
	margin-right: 20px;
	margin-left: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition-property: all;
	transition-duration: 0.5s;
	&:hover {
		transform: rotate(-30deg);
	}
	img {
		width: 110px;
		height: auto;
	}
}
.link-logo-enabled {
	cursor: pointer;
}
.link-logo-disabled {
	cursor: default;
}
.title-and-links {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	h1 {
		font-family: $font-family_1;
		color: $color_1;
		font-size: 40px;
		height: 50%;
		margin-left: 10px;
		margin-bottom: 0;
		margin-top: 0;
		margin-right: 0;
		cursor: default;
	}
	nav {
		width: 99%;
		height: 50%;
		margin-left: 10px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 2.2%;
		font-size: 18px;
		.navlink-active {
			color: $color_1;
		}
		.navlink-inactive {
			color: $color_3;
		}
	}
}
.header-div-connection {
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;
	position: absolute;
	margin-right: 20px;
	right: 30px;
	top: 10px;
	height: fit-content;
	text-align: center;
}
.header-div-logged-image {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 45px;
	height: 45px;
	background-color: $background-color_1;
	outline: 2px solid lightcyan;
	border-radius: 50%;
	cursor: pointer;
	overflow: hidden;
	img {
		width: auto;
		height: 100%;
	}
	&:hover {
		outline: 2px solid white;
	}
}
.connection-link-logout {
	display: flex;
	margin-right: 14px;
	font-size: 16px;
	font-weight: bold;
	text-decoration: underline;
	color: $color_2;
	font-family: $font-family_2;
	cursor: pointer;
	&:hover {
		color: $color_1;
	}
}
.connection-link {
	margin-top: 25px;
	font-size: 16px;
	text-decoration: underline;
	color: $color_2;
	font-weight: bold;
	font-family: $font-family_2;
	cursor: pointer;
	&:hover {
		color: $color_1;
	}
}
nav {
	a {
		text-decoration: none;
		text-transform: lowercase;
		color: $color_2;
		cursor: pointer;
	}
	a.active {
		color: $color_3;
		cursor: default;
	}
}
/* Ici : media queries pour la seconde partie desktop */
@media screen and (max-width: 1380px) {
	.link-logo {
		position: absolute;
		z-index: 3;
		margin-top: 10px;
		width: 50px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition-property: all;
		transition-duration: 0.5s;
		img {
			width: 50px;
			height: 50px;
		}
	}
	.title-and-links {
		nav {
			width: 97%;
			gap: 1.5%;
			position: absolute;
			justify-content: space-around;
			top: 70px;
			font-size: 16px;
		}
		h1 {
			position: absolute;
			top: 0;
			font-size: 35px;
			height: 50%;
			margin-left: 83px;
		}
	}
	.header-div-connection {
		margin-right: 0;
	}
	.connection-link {
		font-size: 14px;
	}
	.connection-link-logout {
		font-size: 14px;
	}
}
/* Ici : media queries pour la première partie tablette */
@media screen and (max-width: 1023px) {
	.link-logo {
		display: none;
	}
	.title-and-links {
		nav {
			display: none;
		}
		h1 {
			position: absolute;
			font-size: 36px;
			margin-left: 10px;
			line-height: 32px;
		}
	}
	.header-div-connection {
		right: 20px;
		top: 80px;
	}
	.connection-link {
		font-size: 14px;
	}
	.connection-link-logout {
		font-size: 14px;
	}
}
/* Ici : media queries pour la seconde partie tablette */
@media screen and (max-width: 991px) {
	.header {
		width: 100%;
	}
}
/* Ici : media queries pour la première partie smartphone */
@media screen and (max-width: 660px) {
	.connection-link {
		font-size: 12px;
	}
	.connection-link-logout {
		font-size: 12px;
	}
}