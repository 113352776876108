$color_1: white;
$color_2: rgb(53, 155, 155);
$color_3: gainsboro;
$color_4: lightcyan;
$background-color_1: transparent;
$background-color_2: black;

.search-bar {
	width: 100%;
	height: 120px;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $background-color_1;
	position: relative;
}
.search-form {
	display: flex;
	width: 60%;
	height: 100%;
	padding-top: 120px;
	padding-bottom: 120px;
	padding-left: 180px;
	padding-right: 180px;
	justify-content: center;
	align-items: center;
	background-color: $background-color_1;
	overflow: hidden;
	position: absolute;
}
.search-input {
	z-index: 2;
	width: 100%;
	height: 45px;
	padding: 0;
	padding-left: 10px;
	border: 1px solid gainsboro;
	text-align: center;
	color: $color_3;
	font-weight: bold;
	font-size: 18px;
	border-radius: 6px;
	background-color: $background-color_2;
	&:focus {
		outline: 4px solid $color_4;
		border: none;
		border-radius: 6px;
	}
	&::placeholder {
		color: $color_2;
	}
}
/* Ici : media queries pour la seconde partie tablette */
@media screen and (max-width: 991px) {
	.search-bar {
		min-width: 500px;
		padding-bottom: 50px;
	}
	.search-input {
		width: 100%;
		height: 35px;
		font-size: 14px;
		border-radius: 6px;
		background-color: $background-color_2;
	}
}