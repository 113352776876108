$color_1: white;
$color_2: rgb(53, 155, 155);
$color_3: black;
$font-family_1: Verdana, Geneva, Tahoma, sans-serif;
$font-family_2: Arial, Helvetica, sans-serif;
$background-color_1: white;
$background-color_2: transparent;

.auth-page {
	width: calc(80% - 2px);
	border: 1px solid $color_1;
}
.login-page-content {
	width: 100%;
	min-height: 100vh;
	color: $color_1;
	background: url('../assets/images/VaderShuttle-Endor.webp') no-repeat center center fixed;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding-bottom: 50px;
	h1 {
		margin-top: 50px;
		margin-bottom: 90px;
		cursor: default;
	}
}
.unvalid-password-text {
	font-size: 12px;
	width: 35%;
	margin: 0;
	padding: 0;
	text-align: center;
}
.login-form {
	font-family: $font-family_1;
	font-weight: bold;
	display: flex;
	flex-direction: column;
	gap: 50px;
	align-items: center;
	justify-content: center;
	width: 100%;
	position: relative;
	.div-register-input-file-wrapper {
		margin-left: auto;
		margin-right: auto;
		width: calc(35% + 20px);
		display: flex;
		justify-content: space-around;
		.register-input-file-undo-upload {
			width: 30px;
			height: 30px;
			margin-left: 6px;
			background: url('../assets/images/undo.svg') no-repeat center center;
			background-size: cover;
			cursor: pointer;
			transition: transform 0.5s ease;
		}
		.register-input-file-undo-upload:hover {
			transform: rotate(-360deg);
		}
	}
}
.login-form-input {
	font-size: 16px;
	text-align: center;
	font-weight: bold;
	width: 35%;
	height: 30px;
	border: none;
	padding-left: 10px;
	padding-right: 10px;
	border-radius: 10px;
	outline: 1px solid $color_2;
	&:focus {
		border: none;
		outline: 4px solid $color_2;
		border-radius: 10px;
	}
	&::placeholder {
		text-align: center;
		font-size: 15px;
	}
}
button {
	cursor: pointer;
	width: 18%;
	height: 35px;
	border: none;
	outline: 1px solid $color_2;
	padding-left: 10px;
	padding-right: 10px;
	border-radius: 10px;
	margin-top: 50px;
	font-size: 16px;
	font-weight: bold;
	&:active {
		border: none;
		outline: 4px solid $color_2;
		border-radius: 10px;
	}
	&:hover {
		outline: 4px solid $color_2;
	}
}
#div-register-input-file {
	cursor: pointer;
	position: relative;
	outline: 1px solid $color_2;
	display: flex;
	justify-content: space-around !important;
	align-items: center;
	width: 100%;
	height: 30px !important;
	background-color: $background-color_1;
	color: #757575;
	font-size: 14px !important;
	font-weight: bold;
	font-family: $font-family_1 !important;
	border-radius: 10px !important;
	text-align: center;
	&:active {
		border: none;
		outline: 4px solid $color_2;
		border-radius: 10px;
	}
}
.div-input-file-content {
	display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: calc(100% - 35px);
    height: 100%;
}
input[type="file"] {
	position: relative;
	z-index: 1;
	width: 100%;
	height: 100%;
	padding: 0;
	opacity: 0;
}
.input-valid-img {
	background-color: $background-color_2;
	width: 25px;
	height: 25px;
	margin-left: 20px;
}
.display-none {
	display: none;
}
.display-flex {
	display: flex;
}
.link-switch {
	margin-top: 40px;
	text-decoration: underline;
	font-family: $font-family_2;
	color: $color_1;
	font-weight: bold;
	cursor: pointer;
	background-color: $background-color_2;
}
/* Ici : media queries pour la première partie tablette */
@media screen and (max-width: 1023px) {
	.div-register-input-file-wrapper {
		width: calc(70% + 10px) !important;
		.register-input-file-undo-upload {
			width: 25px !important;
			height: 25px !important;
			margin-left: 4px;
		}
	}
	.login-form-input {
		font-size: 12px;
		width: 70%;
		height: 25px;
		padding-left: 5px;
		padding-right: 5px;
		&::placeholder {
			font-size: 12px;
		}
	}
	button {
		width: 50% !important;
		height: 25px !important;
		padding-left: 5px;
		padding-right: 5px;
		font-size: 12px;
	}
	#div-register-input-file {
		// width: calc(70% + 10px) !important;
		width: 100% !important;
		height: 25px !important;
		font-size: 12px !important;
	}
	.input-valid-img {
		width: 10px;
		height: 10px;
		margin-left: 0;
	}
}
/* Ici : media queries pour la seconde partie tablette */
@media screen and (max-width: 991px) {
	.auth-page {
		width: calc(100% - 2px);
	}
	.login-page-content {
		h1 {
			font-size: 22px;
		}
	}
	.link-switch {
		text-align: center;
		font-size: 14px;
		width: 90%;
	}
	.forum-div-categories {
		h2 {
			font-size: 18px;
		}
	}
	.div-category {
		width: 100%;
		height: 57px;
		font-size: 16px;
		&:hover {
			font-size: 18px;
		}
	}
	.div-register-input-file-wrapper {
		// max-width: calc(35% + 20px) !important;
		width: calc(70% + 10px) !important;
		justify-content: center;
		.register-input-file-undo-upload {
			width: 15px;
			height: 15px;
			margin-left: 3px;
		}
	}
}