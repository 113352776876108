$background-color_1: #83C1C9;
$background-color_2: rgb(53, 155, 155);
$background-color_3: white;

.switch {
    border: 1px solid $background-color_3;
    border-radius: 20px;
	display: inline-block;
	position: relative;
	width: 70px;
	height: 40px;
	cursor: pointer;
	overflow: hidden;
}
.switch-checkbox {
	position: absolute;
	top: -30px;
	left: -30px;
	width: 0;
	height: 0;
	+ {
		.switch-span {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: $background-color_1;
			border-radius: 20px;
			&:before {
				content: "";
				display: inline-block;
				position: absolute;
				top: 50%;
				left: 4px;
				width: 32px;
				height: 32px;
				background-color: $background-color_3;
				border-radius: 50%;
				transform: translateY(-50%);
				transition: all .5s;
			}
		}
	}
	&:checked {
		+ {
			.switch-span {
				background-color: $background-color_2;
				&:before {
					left: 34px;
				}
			}
		}
	}
}
/* Ici : media queries pour la troisième partie tablette */
@media screen and (max-width: 767px) {
    .switch {
        transform: scale(.7);
    }
}