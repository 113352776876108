$color_1: white;
$font-family_1: Arial, Helvetica, sans-serif;
$background-color_1: black;

.topic-card-main {
	width: 80%;
	height: 90px;
	overflow: hidden;
	border: 2px solid white;
	border-radius: 10px;
	font-family: $font-family_1;
	position: relative;
}
.topic-card-banner {
	width: 110%;
	background-position: center;
	position: absolute;
}
.topic-card-link {
	position: absolute;
	width: 100%;
	height: 100%;
	text-decoration: none;
	display: flex;
	align-items: center;
	background-color: $background-color_1;
	transition: all 0.4s;
	&:hover { 
		background-color: transparent;
	}
}
.topic-card-content {
	display: flex;
	flex-direction: column;
	color: $color_1;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	height: 100%;
	padding-left: 3%;
	padding-right: 3%;
	padding-top: 14px;
	gap: 10px;
}
.topic-infos {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	h1 { 
		font-size: 20px;
		margin: 0;
	}
	.topic-datetime-and-actions {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		p {
			font-size: 14px;
			margin: 0;
		}
		.topic-action {
			line-height: 20px;
			margin-left: 20px;
			cursor: pointer;
		}
		.bulle {
			width: 16px; 
			height: 16px;
			min-width: 16px;
			min-height: 16px;
		}
	}
}
/* Ici : media queries pour la seconde partie desktop */
@media screen and (max-width: 1380px) {
	.topic-card-main {
		width: 90%;
	}
    .topic-infos {
		h1 { 
			font-size: 14px;
		}
		.topic-datetime-and-actions {
			p {
				font-size: 13px;
			}
		}
	}
	.topic-description {
		font-size: 13px;
	}
}
/* Ici : media queries pour la première partie tablette */
@media screen and (max-width: 1023px) {
	.topic-card-main {
		width: 95%;
	}
	.topic-infos {
		h1 { 
			font-size: 14px;
		}
		.topic-datetime-and-actions {
			p {
				font-size: 12px;
			}
		}
	}
	.topic-description {
		font-size: 12px;
	}
}
/* Ici : media queries pour la seconde partie tablette */
@media screen and (max-width: 991px) {
	.topic-card-main {
		min-height: 100px;
		height: fit-content;
	}
	.topic-infos {
		h1 { 
			font-size: 14px;
		}
		.topic-datetime-and-actions {
			p {
				display: none;
				font-size: 12px;
			}
		}
	}
	.topic-description {
		font-size: 12px;
	}
}
/* Ici : media queries pour la première partie smartphone */
@media screen and (max-width: 660px) {
	.topic-infos {
		h1 { 
			font-size: 12px;
		}
		.topic-datetime-and-actions {
			p {
				display: none;
				font-size: 11px;
			}
		}
	}
	.topic-description {
		font-size: 11px;
	}
}