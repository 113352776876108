$color_1: white;
$background-color_1: black;

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.card {
	display: flex;
	justify-content: center;
	z-index: 2;
	margin: 15px;
	width: 400px;
	height: 180px;
	overflow: hidden;
	border: 2px solid lightcyan;
	border-radius: 20px;
	background-color: $background-color_1;
	transition-property: all;
	transition-duration: 1s;
	&:hover { 
		border: 2px solid white;
	}
	.card-home {
		animation: fadein 1s;
	}
	.card-image {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 110%;
		overflow: hidden;
		img { 
			width: 100%;
			height: auto;
		}
	}
}
.card-name-div {
	position: absolute;
	bottom: 0;
	width: 110%;
	min-height: 40px;
	background-color: $background-color_1;
	display: flex;
	justify-content: center;
	align-items: flex-start;
}
.card-link {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	opacity: 0.7;
	transition-property: all;
	transition-duration: 1s;
	&:hover { 
		opacity: 1;
	}
}
.card-name {
	text-align: center;
	font-size: 22px;
	text-transform: lowercase;
	width: 85%;
	height: fit-content;
	color: $color_1;
	margin-top: 0;
	margin-bottom: 5px;
}
/* Ici : media queries pour la première partie smartphone */
@media screen and (max-width: 660px) {
	.card {
		margin-bottom: 10px;
		width: 240px;
		height: 100px;
	}
	.card-name-div {
		min-height: 0;
	}
	.card-name {
		font-size: 16px;
		height: fit-content;
	}
}