$color_1: white;
$color_2: black;
$color_3: #555;
$color_4: gainsboro;
$color_5: rgb(53, 155, 155);
$color_6: lightcyan;
$font-family_1: Arial, Helvetica, sans-serif;
$background-color_1: lightblue;
$background-color_2: lightgray;
$background-color_3: gainsboro;
$background-color_4: #FFFFFF;
$background-color_5: white;
$background-color_6: transparent;

.account-page {
	background: url(../assets/images/millenium_condor_chess_room.webp) no-repeat center center fixed;
	background-size: cover;
	height: fit-content;
	color: $color_1;
	position: relative;
	width: calc(80% - 2px);
	border: 1px solid $color_6 !important;
}
.account-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: $background-color_1;
	opacity: 0.6;
}
.account-content {
	z-index: 1;
	width: 80%;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.account-title {
	color: $color_2;
	margin-top: 50px;
	text-align: center;
	text-transform: lowercase;
	cursor: default;
}
.account-data {
	margin-top: 50px;
	margin-bottom: 50px;
	color: $color_2;
	gap: 40px;
	width: 50%;
	height: fit-content;
	padding: 20px;
	padding-bottom: 40px;
	background: rgba(220, 220, 220, .5);
	border-radius: 8px;
}
.unvalid-password-text {
	font-family: $font-family_1;
	font-size: 12px;
	width: 100%;
	margin: 0;
	padding: 0;
	text-align: center;
}
.account-profile-title {
	width: 100%;
	font-size: 22px;
	text-align: center;
	text-transform: lowercase;
	margin-bottom: 40px;
	cursor: default;
}
.account-user-picture {
	width: 100px;
	height: 100px;
	overflow: hidden;
	outline: 5px solid gainsboro;
	border-radius: 50%;
	background-color: $background-color_2;
	display: flex;
	justify-content: center;
	align-items: center;
	img {
		width: auto;
		height: 100%;
	}
}
.account-user-detail {
	display: flex;
	align-items: baseline;
	gap: 10px;
}
.account-user-key {
	text-transform: lowercase;
	font-size: 20px;
	cursor: default;
}
.account-user-value {
	font-family: $font-family_1;
	font-size: 24px;
	cursor: default;
}
.account-section-separator {
	height: 2px;
	width: 80%;
	background-color: $background-color_3;
	margin-top: 60px;
	margin-bottom: 60px;
	margin-left: auto;
	margin-right: auto;
}
.notifs-section {
	width: 100%;
	margin-bottom: 25px;
	display: flex;
	flex-direction: column;
	align-items: center;
	h2 {
		margin-top: 0;
	}
	.allow-mentions-notifs {
		font-family: $font-family_1;
		font-size: 24px;
		cursor: default;
		width: 100%;
		display: flex;
		justify-content: space-around;
		align-items: center;
	}
}
.any-followed-topic-text {
	font-family: $font-family_1;
	font-size: 24px;
	cursor: default;
	margin-top: 0;
	margin-bottom: 0;
}
.account-form-update-section {
	h2 {
		font-size: 22px;
		text-transform: lowercase;
		text-align: center;
		margin-bottom: 40px;
		cursor: default;
	}
}
.account-form-update {
	display: flex;
	flex-direction: column;
	gap: 40px;
	align-items: center;
	input {
		border: none;
		border-radius: 20px;
		height: 31px;
		margin: 0;
		font-size: 18px;		
		margin-left: auto;
		margin-right: auto;
		background-color: $background-color_5;
		&:focus {
			outline: 1px solid gainsboro;
		}
	}
}
input {
	&:-webkit-autofill {
		background-color: $background-color_4 !important;
		color: $color_3 !important;
		-webkit-box-shadow: 0 0 0 1000px white inset !important;
		-webkit-text-fill-color: #555555 !important;
		&:hover {
			background-color: $background-color_4 !important;
			color: $color_3 !important;
			-webkit-box-shadow: 0 0 0 1000px white inset !important;
			-webkit-text-fill-color: #555555 !important;
		}
		&:active {
			background-color: $background-color_4 !important;
			color: $color_3 !important;
			-webkit-box-shadow: 0 0 0 1000px white inset !important;
			-webkit-text-fill-color: #555555 !important;
		}
		&:focus {
			background-color: $background-color_4 !important;
			color: $color_3 !important;
			-webkit-box-shadow: 0 0 0 1000px white inset !important;
			-webkit-text-fill-color: #555555 !important;
		}
	}
}
.account-input-text-data {
	padding-left: 15px;
	padding-right: 15px;
	width: calc(100% - 30px);
}
.account-form-delete-section {
	input {
		border: none;
		border-radius: 20px;
		width: calc(100% - 30px);
		height: 31px;
		margin: 0;
		font-size: 18px;
		padding-left: 15px;
		padding-right: 15px;
		background-color: $background-color_5;
		&:focus {
			outline: 1px solid gainsboro;
		}
	}
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 40px;
	align-items: center;
}
.div-input-file-wrapper {
	width: 100%;
	display: flex;
	justify-content: space-around;
	.input-file-undo-upload {
		width: 33px;
		height: 33px;
		margin-left: 6px;
		background: url('../assets/images/undo.svg') no-repeat center center;
		background-size: cover;
		cursor: pointer;
		transition: transform 0.5s ease;
	}
	.input-file-undo-upload:hover {
		transform: rotate(-360deg);
	}
}
input[type='file'] {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    cursor: pointer;
	margin-left: auto;
	margin-right: auto;
}
.div-input-file-image {
	display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 20px;
}
.account-label {
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	outline: none;
	border-radius: 20px;
	width: 94%;
	height: 33px;
	margin: 0;
	font-size: 18px;
	color: $color_3;
	padding-left: 15px;
	padding-right: 15px;
	background-color: $background-color_5;
	font-family: $font-family_1;
	cursor: pointer;
	&:active {
		outline: 1px solid gainsboro;
	}
}
.input-valid-img {
	background-color: transparent;
	width: 25px;
	height: 25px;
	margin-left: 20px;
}
.display-none {
	display: none;
}
.display-flex {
	display: flex;
}
.account-submit-button {
	font-family: $font-family_1;
	border: none;
	outline: none;
	border-radius: 20px;
	width: 50%;
	height: 33px;
	margin: 0;
	font-size: 18px;
	padding-left: 15px;
	padding-right: 15px;
	background-color: $background-color_5;
	margin-bottom: 30px;
	cursor: pointer;
	&:active {
		outline: 4px solid gainsboro;
		border-radius: 20px;
	}
	&:hover {
		outline: 4px solid gainsboro;
		border-radius: 20px;
	}
}
.delete-user {
	font-family: $font-family_1;
	border: none;
	outline: none;
	border-radius: 20px;
	width: 50%;
	height: 33px;
	margin: 0;
	font-size: 18px;
	padding-left: 15px;
	padding-right: 15px;
	background-color: $background-color_5;
	margin-bottom: 30px;
	cursor: pointer;
	&:hover {
		padding-left: 15px;
		padding-right: 15px;
		width: fit-content;
		outline: 4px solid gainsboro;
		border-radius: 20px;
	}
	&:active {
		outline: 4px solid gainsboro;
		border-radius: 20px;
	}
}
.account-delete-section {
	gap: 40px;
	width: 100%;
	height: fit-content;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.link-to-admin {
	font-size: 22px;
	text-transform: lowercase;
	margin-bottom: 40px;
	text-decoration: none;
	color: black;
	display: flex;
	justify-content: center;
	outline: 2px solid black;
	border-radius: 10px;
	background-color: rgba(220, 220, 220, .5);
	cursor: pointer;
	&:hover {
		background-color: $color_4;
	}
}
.account-div-return {
	position: absolute;
	z-index: 2;
	display: flex;
	width: 100%;
	justify-content: flex-start;
}
.admin-arrow-link {
	position: absolute;
	margin-left: 15px;
	margin-top: 20px;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	background-color: $background-color_6;
	transition-property: all;
	transition-duration: .2s;
	&:hover {
		filter: invert(1);
	}
	img {
		width: 80px;
		height: 80px;
	}
}
/* Ici : media queries pour la seconde partie desktop */
@media screen and (max-width: 1380px) {
	.account-data {
		min-width: 390px;
	}
}
/* Ici : media queries pour la seconde partie tablette */
@media screen and (max-width: 991px) {
	.account-page {
		width: calc(100% - 2px) !important;
	}
}
/* Ici : media queries pour la troisième partie tablette */
@media screen and (max-width: 767px) {
	.admin-arrow-link {
		max-width: 60px;
		max-height: 60px;
		img {
			max-width: 60px;
			max-height: 60px;
		}
	}
	.account-title {
		font-size: 22px;
		width: 90%;
	}
	.account-profile-title {
		font-size: 18px !important;
	}
	.account-user-picture {
		width: 70px !important;
		height: 70px !important;
	}
	.account-user-key, .account-user-value, .allow-mentions-notifs {
		font-size: 16px !important;
	}
	.any-followed-topic-text {
		font-size: 16px;
	}
	.account-form-update-section {
		h2 {
			font-size: 18px !important;
		}
	}
	.account-form-update {
		gap: 25px !important;
		input {
			height: 25px !important;
			font-size: 14px !important;
		}
	}
	.account-label {
		height: 25px !important;
		font-size: 14px !important;
	}
	.input-valid-img {
		background-color: transparent;
		width: 20px !important;
		height: 20px !important;
		margin-left: 10px !important;
	}
	.account-submit-button {
		height: 25px !important;
		font-size: 14px !important;
	}
	.account-profile-title {
		font-size: 18px;
	}
	.account-form-delete-section {
		input {
			height: 25px !important;
			font-size: 14px !important;
		}
		gap: 25px !important;
	}
	.delete-user {
		width: fit-content !important;
		height: 25px !important;
		font-size: 14px !important;
	}
	.link-to-admin {
		font-size: 18px;
	}
	.input-file-undo-upload {
		width: 25px !important;
		height: 25px !important;
		margin-left: 4px !important;
	}
}
/* Ici : media queries pour la première partie smartphone */
@media screen and (max-width: 660px) {
	.account-content {
		width: 100% !important;
	}
}
/* Ici : media queries pour la seconde partie smartphone */
@media screen and (max-width: 420px) {
	.admin-arrow-link {
		margin-left: 15px;
		margin-top: 20px;
		max-width: 40px;
		max-height: 40px;
		img {
			max-width: 40px;
			max-height: 40px;
		}
	}
	.account-data {
		min-width: calc(100% - 40px) !important;
		margin-top: 50px;
		margin-bottom: 50px;
		color: $color_2;
		gap: 40px;
		height: fit-content;
		background: rgba(220, 220, 220, .5);
		border-radius: 0 !important;
	}
}