$color_1: white;
$color_2: #b4b4b4;

@keyframes extend {
	0% {
		width: 40px;
		height: 40px;
	}
	50% {
		width: 60px;
		height: 60px;
	}
	100% {
		width: 40px;
		height: 40px;
	}
}
.like-thumb-div {
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
	width: 50px;
	height: 50px;
}
.comment-like {
	width: 24px;
	height: 24px;
}
#path1 {
    fill: white;
    fill-opacity: 1;
    stroke: #000000;
    stroke-opacity: 1;
}
#path5 {
	fill: $color_2;
	fill-opacity: 0.59683794;
	stroke: $color_2;
	stroke-opacity: 1;
}
#path6 {
	fill: $color_2;
	fill-opacity: 0.59607846;
}
.like-svg {
	margin: 0;
	cursor: pointer;
	width: 80%;
	height: 80%;
	transition-property: animation;
	&:active { 
		animation: extend .1s;
	}
}
.like-svg-disabled {
	width: 80%;
	height: 80%;
}
.like-counter-div {
	font-family: Arial, Helvetica, sans-serif;
	padding-left: 10px;
	width: 10%;
	height: fit-content;
	z-index: 1;
	display: flex;
	cursor: default;
	.like-counter {
		color: $color_1;
		height: 10px;
	}
}
/* Ici : media queries pour la seconde partie desktop */
@media screen and (max-width: 1380px) {
	.like-thumb-div {
		width: 42px;
		height: 42px;
	}
	.like-counter {
		font-size: 14px;
	}
}
/* Ici : media queries pour la première partie tablette */
@media screen and (max-width: 1023px) {
	.like-thumb-div {
		width: 35px;
		height: 35px;
	}
}
/* Ici : media queries pour la seconde partie tablette */
@media screen and (max-width: 991px) {
	.like-thumb-div {
		width: 30px;
		height: 30px;
	}
	.like-counter {
		font-size: 13px;
	}
}
/* Ici : media queries pour la première partie smartphone */
@media screen and (max-width: 660px) {
	.like-thumb-div {
		width: 25px;
		height: 25px;
	}
	.like-counter {
		font-size: 12px;
	}
}